import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PodcastList from '../components/Podcast/PodcastList';
import IntroCTA from '../components/CTAs/IntroCTA';
import CardSection from '../components/Cards/CardSection';
import RecentCardsSection from '../components/Cards/RecentCardsSection';
import ContactUsCTA from '../components/CTAs/ContactCTA';
import SEO from '../components/SEO'
import { startCase } from 'lodash'
import { updateSearchValue } from '../actions/valueChanges';

const Posts = ({ data, pageContext }) => {
  const posts = data.posts.edges;
  const podcasts = data.podcasts.edges;
  const { basePath } = pageContext;
  const dispatch = useDispatch();

  const atlanticSectionTiles = posts.slice(0, 8);
  const postsSplitIntoSectionsOfEight = posts
  .slice(8)
  .reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/8);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item);
    
    return resultArray;
  }, [])

  useEffect(() => {
    dispatch(updateSearchValue(""));
  }, [])

  
  return (
    <Layout>
      <SEO title={startCase(basePath)} />
      <Container>
        <IntroCTA />
        <RecentCardsSection posts={atlanticSectionTiles} basePath={basePath} />
        <PodcastList podcasts={podcasts} />
        <CardSection posts={postsSplitIntoSectionsOfEight} basePath={basePath} />
        <ContactUsCTA page={'listing'} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!) {
    posts: allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            ogimg: resize(width: 1800) {
              src
            }
          }
          authors {
            title
          }
          metaDescription {
            childMarkdownRemark {
              excerpt(pruneLength: 140)
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 120)
            }
          }
        }
      }
    }
    podcasts: allContentfulPodcast {
      edges {
        node {
          title
          id
          image {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            ogimg: resize(width: 1000) {
              src
            }
          }
          description {
            description
          }
          spotifyLink
          appleLink
          anchorLink
        }
      }
    }
  }
`

export default Posts
