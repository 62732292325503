import React from 'react'
import styled from '@emotion/styled'
import Card from './Card';

const Main = styled.main`

`

const RecentCardList = styled.ul`
    display: grid;
    grid-template-columns: [first] calc(25% - 2rem) [second] 50% [third] calc(25% - 2rem) [end];
    column-gap: 2rem;
    row-gap: 1rem;
    flex-flow: row wrap;
    margin: 0 auto 4rem;
    position: relative;

    &::after {
        @media (min-width: 767px) {
            ${props => props.theme.outlines.tileSectionBorderBottom}
        }
    }

    @media (min-width: 767px) and (max-width: 1020px) {
        grid-template-columns: repeat(4, calc(25% - 1.5rem));
    }

    @media (max-width: 767px) {
        grid-template-columns: 100%;
        margin-bottom: 0;
    }

    li {
        @media (max-width: 767px) {
            grid-column: 1 / 2;
        }

        &:nth-of-type(1) {
            @media (min-width: 1020px) {
                grid-area: 1 / 2 / 6 / 2;
                &::before {
                    ${props => props.theme.outlines.tileSectionBorderLeft}
                }
            }

            @media (min-width: 767px) {
                &::after {
                    ${props => props.theme.outlines.tileSectionBorderRight}
                }
            }

            @media (min-width: 767px) and (max-width: 1020px) {
                grid-area: 1 / 1 / 6 / 3;
            }

            @media (max-width: 767px) {
                grid-row: 1 / 2;

                &::after {
                    ${props => props.theme.outlines.tileSectionBorderBottom}
                    bottom: -1rem;
                }
            }

            .gatsby-image-wrapper {
                padding-bottom: 56.25%;
            }

            article {
                padding-top: 1.5rem;

                h2,
                h3, 
                p {
                    text-align: center;
                }

                h2 {
                    font-size: 2.625rem;
                    line-height: 1.125;

                    @media (max-width: 767px) {
                        font-size: 2.5rem;
                    }
                }

                p {
                    font-size: 1.5rem;
                    line-height: 1.125;

                    @media (max-width: 767px) {
                        font-size: 1.125rem;
                    }
                }
            }
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
            .gatsby-image-wrapper {
                padding-bottom: 56.25%;
            }

            article {
                @media (max-width: 767px) {
                    padding-bottom: 1rem;
                }

                h2 {
                    font-size: 1.5rem;
                    line-height: 1.125;
                }

                p {
                    display: none;

                    @media (max-width: 767px) {
                        display: block;
                    }
                }
            }
        }

        &:nth-of-type(2) {
            grid-area: 1 / 1 / 3 / 2;

            @media (min-width: 1020px) {
                &::after {
                    ${props => props.theme.outlines.tileSectionBorderBottom}
                    bottom: 0;
                }
            }

            @media (min-width: 767px) and (max-width: 1020px) {
                grid-area: 6 / 1 / 6 / 3;
            }

            @media (max-width: 767px) {
                grid-row: 2 / 3;

                &::after {
                    ${props => props.theme.outlines.tileSectionBorderBottom}
                    bottom: -1rem;
                }
            }
        }

        &:nth-of-type(3) {
            grid-area: 3 / 1 / 6 / 2;

            @media (min-width: 767px) and (max-width: 1020px) {
                grid-area: 6 / 3 / 6 / 5;
            }

            @media (max-width: 767px) {
                grid-row: 3 / 4;

                &::after {
                    ${props => props.theme.outlines.tileSectionBorderBottom}
                    bottom: -1rem;
                }
            }
        }

        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8) {
            min-height: 110px;

            a {
                flex-direction: row;
            }

            .gatsby-image-wrapper {
                flex-basis: 5rem;
                height: 5rem;
                flex-shrink: 0;
                margin-left: 1rem;
                order: 1;
            }

            article {
                flex-basis: 100%;
                padding-top: 0;

                h2 {
                    line-height: 1.5rem;
                    font-size: 1.125rem;
                }

                p {
                    display: none;
                }
            }
        }

        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7) {
            &::after {
                ${props => props.theme.outlines.tileSectionBorderBottom}
                bottom: 0;
            }
        }

        &:nth-of-type(4) {
            @media (min-width: 1020px) {
                grid-area: 1 / 3 / 2 / 3;
            }

            @media (min-width: 767px) and (max-width: 1020px) {
                grid-area: 1 / 3 / 2 / 5;
            }

            @media (max-width: 767px) {
                grid-row : 4 / 5;
            }
        }

        &:nth-of-type(5) {
            @media (min-width: 1020px) {
                grid-area: 2 / 3 / 3 / 3;
            }

            @media (min-width: 767px) and (max-width: 1020px) {
                grid-area: 2 / 3 / 3 / 5;
            }

            @media (max-width: 767px) {
                grid-row : 5 / 6;
            }
        }

        &:nth-of-type(6) {
            @media (min-width: 1020px) {
                grid-area: 3 / 3 / 4 / 3;
            }

            @media (min-width: 767px) and (max-width: 1020px) {
                grid-area: 3 / 3 / 4 / 5;
            }

            @media (max-width: 767px) {
                grid-row : 6 / 7;
            }
        }
        &:nth-of-type(7) {
            @media (min-width: 1020px) {
                grid-area: 4 / 3 / 5 / 3;
            }

            @media (min-width: 767px) and (max-width: 1020px) {
                grid-area: 4 / 3 / 5 / 5;
            }

            @media (max-width: 767px) {
                grid-row : 7 / 8;
            }
        }
        &:nth-of-type(8) {
            @media (min-width: 1020px) {
                grid-area: 5 / 3 / 6 / 3;
            }

            @media (min-width: 767px) and (max-width: 1020px) {
                grid-area: 5 / 3 / 6 / 5;
            }
            
            @media (max-width: 767px) {
                grid-row : 8 / 9;
            }
        }
    }
`

const ListItem = styled.li`
    background: red;
    border: 2px solid white;
`

const RecentCardsSection = ({ posts, basePath }) => {

    return (
        <RecentCardList>
            {posts.map(({ node: post }) => {
                return <Card key={post.id} {...post} basePath={basePath} />
                })
            }
        </RecentCardList>
    );
}

export default RecentCardsSection
