import React from 'react'
import styled from '@emotion/styled'
import PodcastItem from './PodcastItem';

const PodcastList = ({ podcasts }) => {    
    const PodcastSection = styled.section`
        margin-bottom: 4rem;

        position: relative;

        &::after {
            ${props => props.theme.outlines.tileSectionBorderBottom}
        }
    `

    const PodcastSectionHeading = styled.h1`
        font-family: ${props => props.theme.fonts.title};
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 2rem;
    `

    const ListOfPodcasts = styled.ul`
        > li:not(:last-of-type) {
            margin-bottom: 4rem;
            position: relative;
            
            &::after {
                ${props => props.theme.outlines.tileSectionBorderBottom}
            }
        }
    `;

    return (
        <PodcastSection>
            <PodcastSectionHeading>Podcasts</PodcastSectionHeading>
            <ListOfPodcasts>
                {podcasts.map((podcast) => {
                    return <PodcastItem podcast={podcast.node} key={podcast.node.id} />
                })}
            </ListOfPodcasts>
        </PodcastSection>
    )
}

export default PodcastList;