import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { hideIntroCTA } from '../../actions/displayChanges';
import logo from "../../assets/logos/short-logo-black.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const CTAContainer = styled.aside`
    width: 70%;
    min-width: 700px;
    margin: 0 auto 2rem;
    padding: 1rem;
    border: 0.125rem solid ${props => props.theme.colors.primary};

    @media(max-width: 767px) {
        width: 100%;
        min-width: unset;
    }

    h2,
    a {
        font-family: ${props => props.theme.fonts.title};
    }
`;

const ExitContainer = styled.div`
    text-align: right;

    button {
        &:hover {
            cursor: pointer;
        }
    }
`;

const CopyContainer = styled.div`
    width: 80%;

    h2 {
        font-size: 2rem;
        line-height: 1.25;
        margin-bottom: 1.5rem;

        @media (max-width: 767px) {
            font-size: 1.125rem;
        }
    }

    @media(max-width:767px) {
        width: 95%;
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    padding: 0.5rem 0.75rem;
    background-color: ${props => props.theme.colors.highlight};
    color: ${props => props.theme.colors.text};
    border: 0.063rem solid transparent;
    transition: all 0.2s ease-in;

    &:hover {
        background-color: #fff;
        border-color: ${props => props.theme.colors.highlight};
        color: ${props => props.theme.colors.secondary};
    }

    @media(max-width: 767px) {
        font-size: 0.875rem;
    }
`;


const ImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    
    img {
        width: 4.688rem;
        align-self: center;

        @media(max-width: 767px) {
            width: 3.125rem;
        }
    }
`;


const IntroCTA = () => {
    const dispatch = useDispatch();

    const introCTADisplay = useSelector(state => state.displayChanges[0].showIntroCTA);
    
    const hideIntroCTAButton = () => {
        dispatch(hideIntroCTA());
    }

    return (
        introCTADisplay ?
        <CTAContainer>
            <ExitContainer>
                <button onClick={hideIntroCTAButton}>
                    <FontAwesomeIcon icon={faTimes} size="1x" color="#000" />
                </button>
            </ExitContainer>
            <CopyContainer>
                <h2>What the f*** is Eklectish?</h2>
                <StyledLink to="/faq" onClick={hideIntroCTAButton}>Find out more</StyledLink>
            </CopyContainer>
            <ImageContainer>
                <img src={logo} alt="ekl logo here"/>
            </ImageContainer>
        </CTAContainer>
        :
        null
    )
}

export default IntroCTA
