import React from 'react'
import styled from '@emotion/styled'
import Card from './Card';
import CardList from './CardList';

const SectionOne = styled.section`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 auto 4rem;
    width: 100%;
    position: relative;

    @media (max-width: 900px) {
        margin-bottom: 2rem;
    }

    &::after {
        @media (min-width: 900px) {
            ${props => props.theme.outlines.tileSectionBorderBottom}
        }
    }

    li {
        flex-basis: calc(35% - 0.9rem);

        .gatsby-image-wrapper {
            padding-bottom: 90%;

            @media (max-width: 900px) {
                padding-bottom: 35%;
            }

            @media (max-width: 450px) {
                padding-bottom: 75%;
            }
        }

        @media (max-width: 900px) {
            flex-basis: 100%;

            &::after {
                ${props => props.theme.outlines.tileSectionBorderBottom}
                bottom: -16px;
            }
        }

        &:first-of-type {
            flex-basis: calc(65% - 0.9rem);

            @media (max-width: 900px) {
                flex-basis: 100%;
            }

            .gatsby-image-wrapper {
                padding-bottom: 45%;

                @media (max-width: 450px) {
                    padding-bottom: 75%;
                }
            }

            &::after {
                @media (min-width: 900px) {
                    ${props => props.theme.outlines.tileSectionBorderRight}
                }
            }
        }

        a {
            min-height: 535px;
            
            @media (max-width: 900px) {
                min-height: 440px;
            }
        }
    }
`

const CardSectionOne = ({ posts, basePath }) => {
    return (
        posts ?
        <SectionOne>
            <CardList>
                {posts.map(({ node: post }) => {
                            return <Card key={post.id} {...post} basePath={basePath} />})
                    }
            </CardList>
        </SectionOne>
        :
        ''
    )
}

export default CardSectionOne