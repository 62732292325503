import React from 'react'
import styled from '@emotion/styled'
import Card from './Card';
import CardList from './CardList';

const SectionThree = styled.section`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 auto 4rem;
    width: 100%;
    position: relative;

    @media (max-width: 900px) {
        margin-bottom: 2rem;
    }

    &::after {
        @media (min-width: 900px) {
            ${props => props.theme.outlines.tileSectionBorderBottom}
        }
    }

    li {
        flex-basis: calc(50% - 0.9rem);

        .gatsby-image-wrapper {
            padding-bottom: 45%;

            @media (max-width: 900px) {
                padding-bottom: 35%;
            }

            @media (max-width: 450px) {
                padding-bottom: 75%;
            }
        }

        @media (max-width: 900px) {
            flex-basis: 100%;

            &::after {
                ${props => props.theme.outlines.tileSectionBorderBottom}
                bottom: -1rem;
            }
        }

        a {
            min-height: 440px;
            max-height: 550px;
        }

        &:nth-of-type(2) {
            @media (min-width: 900px) {
                &::after {
                    ${props => props.theme.outlines.tileSectionBorderLeft}
                }
            }
        }
    }
`

const CardSectionThree = ({ posts, basePath }) => {
    return (
        posts ?
        <SectionThree>
            <CardList>
                {
                    posts.map(({ node: post }) => {
                        return <Card key={post.id} {...post} basePath={basePath} />
                    })
                }
            </CardList>
        </SectionThree>
        :
        ''
    )
}

export default CardSectionThree;