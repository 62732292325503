import React from 'react'
import styled from '@emotion/styled'
import CardSectionOne from './SectionOne';
import AsideCardSection from './AsideSection';
import CardSectionTwo from './SectionTwo';
import CardSectionThree from './SectionThree';

const CardList = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin: 0 auto;
    
    &::after {
        content: '';
        flex: 0 0 32%;
    }
`

const MoreContentHeading = styled.h1`
    font-family: ${props => props.theme.fonts.title};
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2rem;
`

const CardSection = ({ posts, basePath }) => {

    const chunkedPostsArray = posts.map(array => {
        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / 2);

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
        }, [])
    })

    return (
        <main>
            <MoreContentHeading>Keep reading</MoreContentHeading>
                {
                    chunkedPostsArray.map((tileGrouping, i) => {
                        return <CardList key={i}>
                            <CardSectionOne posts={tileGrouping[0]} basePath={basePath} key={`section-one-${i}`}  />
                            {tileGrouping[2] ? 
                            <AsideCardSection posts={tileGrouping[1]} basePath={basePath} key={`aside-section-${i}`} centeredFormat={false} />
                            :
                            <AsideCardSection posts={tileGrouping[1]} basePath={basePath} key={`aside-section-${i}`} centeredFormat={true} />
                            }
                            <CardSectionTwo posts={tileGrouping[2]} basePath={basePath} key={`section-two-${i}`}/>
                            <CardSectionThree posts={tileGrouping[3]} basePath={basePath} key={`section-three-${i}`} />
                        </CardList>
                    })
                }
        </main>
    );
}

export default CardSection
