import React from 'react'
import styled from '@emotion/styled'
import Card from './Card';
import CardList from './CardList';

const AsideSection = styled.section`
    display: flex;
    width: 30%;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 0 4rem 0;
    padding-right: 0.9rem;
    position: relative;

    @media (max-width: 900px) {
        width: 100%;
        padding-right: 0
    }

    &::after {
        ${props => props.theme.outlines.tileSectionBorderBottom}
    }

    li {
        height: calc(50% - 0.9rem);
        min-height: 300px;
        margin-top: 0.9rem;
        flex-basis: 100%;

        .gatsby-image-wrapper {
            display: none;
        }

        &:first-of-type {
            margin-bottom: 0.9rem;
            margin-top: 0;
        }
        
        &:nth-of-type(2) {
            &::after {
                ${props => props.theme.outlines.tileSectionBorderTop}
            }
        }

        @media (max-width: 900px) {
            height: auto;
        }
    }

    &.centered-tiles {
        @media (min-width: 900px) {
            padding-right: 0;
            width: 100%;

            li {
                flex-basis: calc(50% - 0.9rem);
                height: auto;
                margin-top: 0;

                .gatsby-image-wrapper {
                    display: block;
                    padding-bottom: 45%;
                }

                a {
                    min-height: 440px;
                    max-height: 550px;
                }

                &:first-of-type {
                    &::after {
                        display: none;
                    }
                }

                &:nth-of-type(2) {
                    &::after {
                        @media (min-width: 900px) {
                            ${props => props.theme.outlines.tileSectionBorderLeft}
                        }
                    }
                }
            }
        }
    }
`

const AsideCardSection = ({ posts, basePath, centeredFormat }) => {
    const determineCenteredFormat = () => {
        if (!centeredFormat) {
            return;
        }

        return "centered-tiles";
    }

    return (
        posts ?
        <AsideSection className={determineCenteredFormat()}>
            <CardList>
                {
                    posts.map(({ node: post }) => {
                        return <Card key={post.id} {...post} basePath={basePath} />
                    })
                }
            </CardList>
        </AsideSection>
        :
        ''
    )
}

export default AsideCardSection;