import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Post = styled.li`
  position: relative;
  margin: 0 0 1rem 0;
  width: 100%;
  transition: ${props => props.theme.effects.transition};

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 0 0;
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
  }

  @media (max-width: 900px) {
    flex-basis: 100%;
    margin-bottom: 2rem;
  }

  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.text};
    text-decoration: none;

    .gatsby-image-wrapper {
      height: 0;
    }
    
    &:hover {
      h4 {
        color: ${props => props.theme.colors.highlight};
      }
    }
  }
`

const StyledImg = styled(Img)`

`

const Title = styled.h2`
  font-size: 2.25rem;
  margin-bottom: 1rem;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.title};
`

const Author = styled.h3`
  color: ${props => props.theme.colors.secondary};
  font-family: ${props => props.theme.fonts.copy};
  font-weight: 500;
  margin-bottom: 0.5rem;
`

const Excerpt = styled.p`
  line-height: 1.6;
  font-family: ${props => props.theme.fonts.copy};
  font-weight: 400;
`

const PostDetailsContainer = styled.article`
  padding: 1rem 0.5rem 0.5rem;
  height: auto;
`

const Card = ({ slug, heroImage, title, authors, body, metaDescription, ...props }) => {
  const authorName = authors ? authors[0].title : ''
  const metaDescriptionText = metaDescription.childMarkdownRemark.excerpt;
  return (
    <>
      {heroImage && body && (
        <Post featured={props.featured}>
          <Link to={`${props.basePath}/${slug}/`}>
            <StyledImg fluid={heroImage.fluid} backgroundColor={'#eeeeee'} />
            <PostDetailsContainer>
              <Title>{title}</Title>
              <Author>{authorName ? authorName : 'Test Author'}</Author>
              <Excerpt>
                {metaDescriptionText}
              </Excerpt>
            </PostDetailsContainer>
          </Link>
        </Post>
      )}
    </>
  )
}

export default Card
