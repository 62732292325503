import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPodcast  } from '@fortawesome/free-solid-svg-icons'
import { faSpotify } from '@fortawesome/free-brands-svg-icons';
import Img from 'gatsby-image'
import ApplePodcastLogo from '../../../static/images/apple-podcast-icon.png'
import AnchorPodcastLogo from '../../../static/images/anchor-podcast-icon.png'

const PodcastItem = ({ podcast }) => {
    const name = podcast.title;
    const description = podcast.description.description;
    const spotifyLink = podcast.spotifyLink;
    const appleLink = podcast.appleLink;
    const anchorLink = podcast.anchorLink;
    const image = podcast.image.fluid;

    const Podcast = styled.li`
        display: flex;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }
    `;

    const StyledImg = styled(Img)`
        width: 20%;
        min-width: 205px;
        border-radius: 0.9rem;
        align-self: center;

        @media (max-width: 767px) {
            width: 50%;
        }
    `;

    const PodcastCopy = styled.div`
        width: calc(70% - 1.25rem);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 1.25rem;

        @media (max-width: 767px) {
            width: 95%;
            margin: 0.9rem 0;
            align-items: center;
        }

        h1 {
            font-family: ${props => props.theme.fonts.title};
            font-size: 2rem;
            font-weight: 700;

            @media (max-width: 767px) {
                text-align: center;
            }
        }

        h3 {
            font-family: ${props => props.theme.fonts.copy};
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 1.25;
            margin-top: 1.25rem;

            @media (max-width: 767px) {
                font-size: 1rem;
                text-align: center;
            }
        }
    `;

    const PodcastListenContainer = styled.div`
        width: 10%;
        min-width: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            font-family: ${props => props.theme.fonts.title};
            font-size: 1.25rem;
            margin-bottom: 0.625rem;
            color: ${props => props.theme.colors.secondary};
        }
    `;

    const PodcastLinks = styled.ul`
        display: flex;

        li:nth-of-type(2) {
            margin: 0 0.313rem;
        }
    `;

    const PodcastLink = styled.li`
        a {
            img {
                width: 2rem;
    
                &.anchor-logo {
                    width: 1.625rem;
                }
            }
        }
    `;

    return (
        <Podcast>
            <StyledImg fluid={image} backgroundColor={'#eeeeee'} />
            <PodcastCopy>
                <h1>{name}</h1>
                <h3>{description}</h3>
            </PodcastCopy>
            <PodcastListenContainer>
                <h2>Listen Here</h2>
                <PodcastLinks>
                    {spotifyLink ?
                        <PodcastLink>
                            <a href={spotifyLink} target="_blank" title="Link to podcast on Spotify">
                                <FontAwesomeIcon icon={faSpotify} size="2x" color="#1DB954" />
                            </a>
                        </PodcastLink>
                    :
                    null
                    }
                    {anchorLink ?
                        <PodcastLink>
                            <a href={anchorLink} target="_blank" title="Link to podcast on Anchor">
                                <img className="anchor-logo" src={AnchorPodcastLogo} alt="Anchor Logo" />
                            </a>
                        </PodcastLink>
                    :
                    null
                    }
                    {appleLink ? 
                        <PodcastLink>
                            <a href={appleLink} target="_blank" title="Link to podcast on Apple Podcasts">
                                <img src={ApplePodcastLogo} alt="Apple Podcasts Logo" />
                            </a>
                        </PodcastLink>
                    :
                    null
                    }
                </PodcastLinks>
            </PodcastListenContainer>
        </Podcast>
    )
}

export default PodcastItem;