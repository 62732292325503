import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby'

const CTA = styled(Link)`
    display: block;
    width: 100%;
    margin: 2rem auto;
    max-width: 850px;
    text-decoration: none;
    padding: 3.75rem 1.875rem;
    text-align: center;
    border: 0.125rem solid ${props => props.theme.colors.secondary};
    background: transparent;
    border-radius: 0.469rem;
    line-height: 1.5;
    font-weight: 400;
    background-color: ${props => props.theme.colors.secondary};
    font-family: ${props => props.theme.fonts.title};
    color: ${props => props.theme.colors.white};
    transition: ${props => props.theme.effects.transition};
    font-size: 1.25rem;

    &.cta-on-listings {
        max-width: unset;
        margin: 0 auto 2rem auto;
        font-size: 1.5rem;

        @media (max-width: 767px) {
            font-size: 1rem;
        }
    }
    
    &:hover {
        border-color: ${props => props.theme.colors.secondary};
        box-shadow: ${props => props.theme.effects.boxShadow};
    }
`

const ContactUsCTA = ({ page, category }) => {

    const determineCTAText = (pageType = '', category = '') => {
        switch (pageType) {
            case 'post':
                return 'Want to respond or write your own piece? Here\'s how to contribute';
                break;
            case 'podcast':
                return 'Want to record a podcast and share it with the world? Here\'s how to contribute'
            case 'category':
                return `Want to write about ${category}? We'd love to add your voice to our community!`
            case 'column':
                return `Want to write a column of your own? We'd love to add your voice to our community!`
            default:
                return 'Want to share your ideas on Eklectish? Here\'s how to contribute'
                break;
        }
    }

    const setClass = (page) => {
        return page === 'listing' ?
        'cta-on-listings'
        : page === 'category' ?
        'cta-on-listings' 
        : page === 'column' ?
        'cta-on-listings'
        : ''
    }


    return (
        <CTA to={'/contact'} className={setClass(page)}>
            {determineCTAText(page, category)}
        </CTA>
    )
};

export default ContactUsCTA;