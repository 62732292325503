import React from 'react'
import styled from '@emotion/styled'
import Card from './Card';
import CardList from './CardList';

const SectionTwo = styled.section`
    display: flex;
    width: 70%;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 0 4rem 0;
    padding-left: 0.9rem;
    position: relative;

    @media (max-width: 900px) {
        width: 100%;
        padding-left: 0;
    }

    @media (max-width: 900px) {
        margin-bottom: 2rem;
    }

    &::after {
        @media (min-width: 900px) {
            ${props => props.theme.outlines.tileSectionBorderBottom}
        }
    }

    li {
        flex-basis: calc(50% - 0.9rem);
        height: 100%;

        @media (max-width: 900px) {
            flex-basis: 100%;
            height: auto;

            a {
                min-height: 440px;
            }

            &::after {
                ${props => props.theme.outlines.tileSectionBorderBottom}
                bottom: -1rem;
            }
        }

        .gatsby-image-wrapper {
            padding-bottom: 70%;

            @media (max-width: 900px) {
                padding-bottom: 35%;
            }

            @media (max-width: 450px) {
                padding-bottom: 75%;
            }
        }
        &:first-of-type {
            @media (min-width: 900px) {
                &::after {
                    ${props => props.theme.outlines.tileSectionBorderRight}
                }
                
                &::before {
                    ${props => props.theme.outlines.tileSectionBorderLeft}
                }
            }
        }
    }
`

const CardSectionTwo = ({ posts, basePath }) => {
    return (
        posts ?
        <SectionTwo>
            <CardList>
                {
                    posts.map(({ node: post }) => {
                        return <Card key={post.id} {...post} basePath={basePath} />
                    })
                }
            </CardList>
        </SectionTwo>
        :
        ''
    )
}

export default CardSectionTwo;